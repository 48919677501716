// src/views/PetitionPage.js
import React from 'react';
import { Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import PetitionForm from '../components/PetitionForm';
import PetitionDetails from '../components/PetitionDetails';
import SupportSection from '../components/SupportSection';
import Footer from '../components/Footer';
import RecentSignatures from '../components/RecentSignatures'; 

const PetitionPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg">
      <Header />
      <HeroSection />
      <Grid container spacing={4} direction={isMobile ? 'column' : 'row'}>
        {isMobile ? (
          <>
            <Grid item xs={12}>
            <div id="petition-form">
                <PetitionForm />
              </div>
              <RecentSignatures />
            </Grid>
            <Grid item xs={12}>
              <PetitionDetails />
              <SupportSection />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={8}>
              <PetitionDetails />
              <SupportSection />
            </Grid>
            <Grid item xs={12} md={4}>
              <div id="petition-form">
                <PetitionForm />
              </div>
              <RecentSignatures />
            </Grid>
          </>
        )}
      </Grid>
      <Footer />
    </Container>
  );
};

export default PetitionPage;
